.form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-container .form-group {
    width: 100%;
}

.form-container form,
.form-container .calculation-name-container {
    max-width: 750px;
    min-width: 300px;
    box-sizing: content-box;
}

.form-container h3 {
    margin-bottom: 35px;
}

.form-container hr {
    background-color: #69797e;
    width: 100%;
}

.form-container form input[type="number"] {
    -moz-appearance: textfield;
}

.form-container .buttons-container {
    max-width: 794px;
}

.form-container .form-check > label {
    position: relative;
    left: -1.25rem;
}

.form-container .form-check {
    margin-bottom: 1rem;
}

.form-container .hidden {
    display: none;
}

.form-container .buttons-container {
    text-align: center;
}

.form-json-container {
    border-bottom: 1px solid;
    max-width: 800px;
}

    .form-json-container pre {
        color: #69797e;
    }

.calculate-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.form-container hr.divider {
    margin-top: 0px;
    margin-bottom: 0px;
}

.form-container label.divider-name {
    margin-bottom: 0px;
}

.form-container .form-content-container {
    flex-basis: 700px;
    flex-shrink: 1;
}

.form-container .input-fields-container {
    width: 100%;
    display: flex;
}

    .form-container .input-fields-container > div {
        flex-grow: 1;
    }

    .form-container .input-fields-container .bool-option {
        flex-grow: 0;
        width: 60px;
    }

    .form-container .input-fields-container .tooltips {
        margin-left: 15px;
        margin-right: 0px !important;
    }

    .form-container .input-fields-container .tooltiptext {
        width: 235px;
    }

        .form-container .input-fields-container .tooltiptext img {
            width: 100%;
            height: auto;
        }

.form-container .list-input-container {
    width: 100%;
}

.form-container .list-toggle {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

    .form-container .list-toggle svg {
        margin-left: auto;
    }

.form-container .list-input-fields-container {
    background-color: #fff;
    background-clip: padding-box;
    padding: 0.375rem 0.75rem;
    padding-top: 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.form-container .list-input-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

    .form-container .list-input-item.check {
        padding-left: 1.25rem;
    }

    .form-container .list-input-item svg {
        height: 25px;
        width: auto;
        color: #69797e;
        margin-left: auto;
        padding-left: 10px;
        cursor: pointer;
    }

.form-container .add-button-container {
    text-align: center;
}

.form-control:disabled {
    background-color: white;
}

.modal-table {
    border: 2px solid transparent;
    padding: 0;
    height: auto;
}

    .modal-table table,
    .modal-table th,
    .modal-table td {
        border: 1px solid #69797e;
    }

        .modal-table table:hover {
            outline: 2px solid #69797e;
        }

.form-columns-container {
    display: flex;
    flex-direction: row;
    gap: 150px;
    width: 100%;
}

.form-explanations-container {
    flex: 0 1 300px;
    overflow-x: hidden;
}

@media only screen and (max-width: 1300px) {
    .form-columns-container {
        gap: 50px;
    }
}

@media only screen and (max-width: 1000px) {
    .form-explanations-container {
        display: none;
    }
}
