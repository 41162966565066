.tooltips {
    position: relative;
}

.tooltips img {
    pointer-events: none;
}

.tooltips-modal {
    display: flex;
    align-items: center;
}

/* GENERAL */

.tooltips .tooltiptext {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    background-color: white;
    color: #69797e;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    margin: 0;
    border: 1px solid #69797e;
}

.tooltips:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* RIGHT */

.tooltips .tooltiptext-right {
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 110%;
}

.tooltiptext-right:after,
.tooltiptext-right:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.tooltiptext-right:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 6px;
    margin-top: -6px;
}

.tooltiptext-right:before {
    border-color: rgba(105, 121, 126, 0);
    border-right-color: #69797e;
    border-width: 7px;
    margin-top: -7px;
}

/* LEFT */

.tooltips .tooltiptext-left {
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 110%;
}

.tooltiptext-left:after,
.tooltiptext-left:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.tooltiptext-left:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #fff;
    border-width: 6px;
    margin-top: -6px;
}

.tooltiptext-left:before {
    border-color: rgba(105, 121, 126, 0);
    border-left-color: #69797e;
    border-width: 7px;
    margin-top: -7px;
}

/* BOTTOM */

.tooltips .tooltiptext-bottom {
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 130%;
}

.tooltiptext-bottom:after,
.tooltiptext-bottom:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.tooltiptext-bottom:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 6px;
    margin-left: -6px;
}

.tooltiptext-bottom:before {
    border-color: rgba(105, 121, 126, 0);
    border-bottom-color: #69797e;
    border-width: 7px;
    margin-left: -7px;
}

/* TOP */

.tooltips .tooltiptext-top {
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 130%;
}

.tooltiptext-top:after,
.tooltiptext-top:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.tooltiptext-top:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 6px;
    margin-left: -6px;
}

.tooltiptext-top:before {
    border-color: rgba(105, 121, 126, 0);
    border-top-color: #69797e;
    border-width: 7px;
    margin-left: -7px;
}

@media only screen and (max-width: 790px) {
    .tooltiptext {
        display: none !important;
    }
    .tooltips img:hover {
        cursor: pointer;
    }
    .tooltips img {
        pointer-events: all;
    }
}
