.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;
}

.section-title-container {
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    padding: 5px 20px;
    position: relative;
}
.section-actions-container {
    position: absolute;
    top: 0;
    right: 0;
}

.paper-max-width {
    max-width: 794px;
}

.section-add-new {
    background-color: white;
    color: #69797e;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #69797e;
    width: 50%;
    padding: 5px 20px;
    text-align: center;
    cursor: pointer;
}

.section-add-new:first-child {
    margin-right: 10px;
}

.section-add-new:last-child {
    margin-left: 10px;
}

.section-title-container h3 {
    color: black;
}
