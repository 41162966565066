.header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.with-image {
    height: 400px;
    background-image: url("Header.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.header-container h1 {
    color: white;
    font-family: "ReadVentor";
    font-size: 60px;
    text-align: center;
}
.header-text-container {
    width: 70%;
}
#header-background {
    width: 100%;
    height: auto;
    position: relative;
}
.iconsContainer {
    display: flex;
    position: absolute;
    right: 60px;
    top: 20px;
    width: 300px;
    justify-content: flex-end;
    padding: 10px;
}
.leftSideIcons {
    display: flex;
    justify-content: space-evenly;
    width: 175px;
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropbtn {
    background-color: #69797e;
    font-family: "ReadVentor";
    font-size: 12px;
    text-align: center;
    color: white;
    cursor: pointer;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    flex-direction: row;
    border: none;
    height: 35px;
    width: 100%;
    min-width: 100px;
    justify-content: space-around;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
}
.btn-icon {
    width: 20px;
}
.dropdown-content-header {
    display: none;
    position: absolute;
    background-color: #69797e;
    z-index: 1;
    color: white;
    border-radius: 4px;
    width: 100%;
}
.dropdown-content-header div {
    text-align: center;
    color: white;
    padding: 6px 4px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    width: 100%;
}
.show {
    display: block;
}
#edit-company-button {
    background-color: #69797e;
    color: white;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    box-sizing: border-box;
}
#edit-company-button > svg {
    width: 29px;
    height: 29px;
}
.project-image-container {
    display: flex;
    flex-wrap: wrap;
    width: 35px !important;
    height: 35px !important;
    margin-left: 15px;
    margin-right: 15px;
}
.project-image-container img {
    display: inline-flex;
    box-sizing: border-box;
    width: 35px !important;
    height: 35px !important;
    border-radius: 4px;
    object-position: 0 0;
}
.header-alert-container {
    position: absolute;
    right: 50%;
    z-index: 10;
    top: 30px;
    padding: 10px;
    transform: translate(50%, 0);
    max-width: 70%;
}
.alert-msg-container {
    border: 1px solid #69797e;
    background-color: white;
    color: #69797e;
    border-radius: 4px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    width: 100%;
}
.alert-msg-container p {
    white-space: nowrap;
    margin: 0px;
    min-width: 0px;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}
.alert-msg-container svg {
    margin-left: 15px;
    cursor: pointer;
    flex-shrink: 0;
}
@media only screen and (max-width: 1500px) {
    .header-alert-container {
        max-width: 50%;
        transform: translate(40%, 0);
    }
}
@media only screen and (max-width: 1250px) {
    .header-alert-container {
        max-width: 50%;
        transform: translate(30%, 0);
    }
}
@media only screen and (max-width: 1100px) {
    .header-alert-container {
        max-width: 50%;
        transform: translate(20%, 0);
    }
}
@media only screen and (max-width: 1000px) {
    .header-alert-container {
        max-width: 50%;
        transform: translate(10%, 0);
    }
}
@media only screen and (max-width: 900px) {
    .header-alert-container {
        max-width: 50%;
        transform: translate(0%, 0);
    }
}
