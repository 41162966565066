.company-user-item-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 10px 10px 0 0;
    padding-right: 35px;
}

.company-user-item-container p {
    margin: 0;
    margin-left: 15px;
}

.company-user-item-container img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.company-user-item-container div {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
}

.buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}
