.edit-company-container {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.edit-company-container .edit-company-details {
    padding-right: 50px;
    width: 100%;
}

.edit-company-container .dropzone-container {
    padding-right: 50px;
    min-width: 465px;
}

.edit-company-container .dragndrop-container {
    max-height: 100px;
}

.edit-company-container .edit-company-employees {
    width: 100%;
    margin-bottom: 20px;
}

.edit-company-container .buttons-container {
    max-width: 900px;
}

.edit-company-container h4 {
    margin-bottom: 20px;
}

.company-content-container {
    width: 100%;
}

@media (min-width: 750px) {
    .edit-company-container form {
        width: 450px;
    }
}

@media (min-width: 1000px) {
    .company-content-container > div {
        width: 45%;
    }

    .company-content-container {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 1200px) {
    .edit-company-container {
        display: block;
    }
}

.edit-company-buttons {
    display: block;
}

.error-message {
    min-height: 24px;
    margin: 0px;
    text-align: center;
    color: #ed4337 !important;
    display: block;
    margin-top: 10px;
}

.company-image-container {
    width: 465px;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    padding: 0;
}

.company-image-container img {
    display: inline-flex;
    border-radius: 2px;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
}
