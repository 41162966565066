.list-group {
    margin-top: 20px;
    overflow: auto;
    height: calc(100vh - 355px);
}
.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.list-group-item:first-child {
    border-radius: 16px 16px 0 0;
}
.list-group-item:last-child {
    border-radius: 0 0 16px 16px;
}
.list-group-item:only-child {
    border-radius: 16px;
}
.list-group-item p,
.list-group-item #user-avatar {
    margin: 0;
}
.list-group-item a {
    color: #69797e;
    font-weight: bold;
    text-decoration: underline !important;
}
.list-group-item #user-avatar {
    height: 25px;
    width: 25px;
    align-self: center;
    margin-right: 5px;
}
.message,
.timestamp {
    text-align: center;
}
.loading-events {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #69797e;
}
.load-more {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #69797e;
    transition: all 0.2s ease-in-out;
}
.load-more:hover {
    text-decoration: underline;
    cursor: pointer;
}
@media (max-width: 900px) {
    .list-group {
        height: calc(100vh - 400px);
    }
}
@media (max-width: 600px) {
    .list-group {
        height: unset;
    }
}
