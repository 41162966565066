.input {
    width: 550px;
}

.input-small {
    width: 400px;
}

.chapterCreate-container {
    width: 80%;
}

.buttons {
    justify-content: space-around;
}
