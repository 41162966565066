.info-message {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #69797e;
    border-radius: 4px;
    justify-content: center;
}

.info-message p {
    padding: 0;
    margin: 0;
    font-size: 14px;
    display: inline;
}

.info-message img {
    margin-right: 5px;
}