.projectDetails-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 550px;
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16;
}

.thumbsContainer img {
    display: inline-flex;
    border-radius: 2px;
    margin-bottom: 8px;
    margin-right: 8px;
    margin-top: 30px;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
}

.image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 550px;
}

.projectDetails-container h5 {
    font-weight: bold;
}

.company-users {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
}
