
.explanation-container-large {
    height: auto;
    margin-bottom: 38px;
    overflow-x: hidden;
}

.explanation-container-medium {
    height: auto;
    margin-bottom: 38px;
    overflow-x: hidden;
}

.explanation-container-small {
    height: auto;
    margin-bottom: 38px;
    overflow-x: hidden;
}

.explanation-container-extra {
    height: auto;
    margin-bottom: 38px;
    overflow-x: hidden;
}

.explanation-container .list-toggle {
    border-radius: 10px 10px 0 0;
    justify-content: center !important;
    transition: border-radius 500ms linear;
    height: auto;
    text-align: center !important;
}

    .explanation-container .list-toggle p {
        margin-bottom: 0px;
        margin-left: auto;
        align-self: center;
    }

.explanation-container.closed .list-toggle {
    border-radius: 10px 10px 10px 10px;
    justify-content: center !important;
}

.explanation-container .border-bottom {
    border-radius: 0 0 10px 10px;
}

.explanation-container img {
    width: 100%;
    height: auto;
}

.explanation-container p {
    font-size: 12px;
    margin-bottom: 2px;
}

#explanation-title {
    font-size: 14px;
}

@media only screen and (max-width: 1200px) {
    .explanation-container p {
        font-size: 12px;
    }
}
/*large*/
.explanation-container-large .list-toggle {
    border-radius: 10px 10px 0 0;
    justify-content: center !important;
    transition: border-radius 500ms linear;
    height: auto;
    text-align: center !important;
}

    .explanation-container-large .list-toggle p {
        margin-bottom: 0px;
        margin-left: auto;
        align-self: center;
    }

.explanation-container-large.closed .list-toggle {
    border-radius: 10px 10px 10px 10px;
    justify-content: center !important;
}

.explanation-container-large .border-bottom {
    border-radius: 0 0 10px 10px;
}

.explanation-container-large img {
    width: 100%;
    height: auto;
}

.explanation-container-large p {
    font-size: 12px;
    margin-bottom: 2px;
}

#explanation-title-large {
    font-size: 14px;
}

@media only screen and (max-width: 1200px) {
    .explanation-container-large p {
        font-size: 12px;
    }
}
/*medium*/
.explanation-container-medium .list-toggle {
    border-radius: 10px 10px 0 0;
    justify-content: center !important;
    transition: border-radius 500ms linear;
    height: auto;
    text-align: center !important;
}

    .explanation-container-medium .list-toggle p {
        margin-bottom: 0px;
        margin-left: auto;
        align-self: center;
    }

.explanation-container-medium.closed .list-toggle {
    border-radius: 10px 10px 10px 10px;
    justify-content: center !important;
}

.explanation-container-medium .border-bottom {
    border-radius: 0 0 10px 10px;
}

.explanation-container-medium img {
    width: 100%;
    height: auto;
}

.explanation-container-medium p {
    font-size: 12px;
    margin-bottom: 2px;
}

#explanation-title-medium {
    font-size: 14px;
}

@media only screen and (max-width: 1200px) {
    .explanation-container-medium p {
        font-size: 12px;
    }
}

/*small*/
.explanation-container-small .list-toggle {
    border-radius: 10px 10px 0 0;
    justify-content: center !important;
    transition: border-radius 500ms linear;
    height: auto;
    text-align: center !important;
}

    .explanation-container-small .list-toggle p {
        margin-bottom: 0px;
        margin-left: auto;
        align-self: center;
    }

.explanation-container-small.closed .list-toggle {
    border-radius: 10px 10px 10px 10px;
    justify-content: center !important;
}

.explanation-container-small .border-bottom {
    border-radius: 0 0 10px 10px;
}

.explanation-container-small img {
    width: 100%;
    height: auto;
}

.explanation-container-small p {
    font-size: 12px;
    margin-bottom: 2px;
}

#explanation-title-small {
    font-size: 14px;
}

@media only screen and (max-width: 1200px) {
    .explanation-container-small p {
        font-size: 12px;
    }
}
/*extra*/
.explanation-container-extra .list-toggle {
    border-radius: 10px 10px 0 0;
    justify-content: center !important;
    transition: border-radius 500ms linear;
    height: auto;
    text-align: center !important;
}

    .explanation-container-extra .list-toggle p {
        margin-bottom: 0px;
        margin-left: auto;
        align-self: center;
    }

.explanation-container-extra.closed .list-toggle {
    border-radius: 10px 10px 10px 10px;
    justify-content: center !important;
}

.explanation-container-extra .border-bottom {
    border-radius: 0 0 10px 10px;
}

.explanation-container-extra img {
    width: 100%;
    height: auto;
}

.explanation-container-extra p {
    font-size: 12px;
    margin-bottom: 2px;
}

#explanation-title-extra {
    font-size: 14px;
}

@media only screen and (max-width: 1200px) {
    .explanation-container-extra p {
        font-size: 12px;
    }
}
