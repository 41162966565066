.user-avatar-container {
    margin-top: 10px;
    text-align: center;
}

.user-avatar-container p {
    margin-bottom: 5px;
    opacity: 1;
    font-size: 10px;
    height: 1rem;
}

.user-avatar-container h5 {
    font-size: 1rem;
    margin-bottom: 4px;
}

.collapsed .user-avatar-container p {
    margin-bottom: 0px;
    height: 0px;
    opacity: 0;
}

.collapsed .user-avatar-container h5 {
    margin-bottom: 4px;
}

#user-avatar {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
    border-radius: 50%;
    transition: width 1s, height 1s, margin-bottom 1s;
    transition-delay: 1s;
}

.collapsed #user-avatar {
    width: 35px;
    height: 35px;
    margin-bottom: 0px;
    transition-delay: 0s;
}

@media (min-height:850px) {
    .user-avatar-container {
        margin-top: 15px;
    }

    #user-avatar {
        width: 130px;
        height: 130px;
        margin-bottom: 20px;
    }

    .collapsed #user-avatar {
        width: 50px;
        height: 50px;
    }

    .collapsed .user-avatar-container h5 {
        margin-bottom: 8px;
    }

    .user-avatar-container p {
        margin-bottom: 10px;
        height: 1.4rem;
        font-size: 14px;
    }

    .user-avatar-container h5 {
        font-size: 1.25rem;
        margin-bottom: 8px;
    }
}