@font-face {
    font-family: "ReadVentor";
    src: local("ReadVentor"),
        url(./fonts/texgyreadventor-regular.otf) format("truetype");
}

@font-face {
    font-family: "ReadVentorBold";
    src: local("Gothic"),
        url(./fonts/texgyreadventor-bold.otf) format("truetype");
}

@font-face {
    font-family: "ReadVentorItalic";
    src: local("Gothic"),
        url(./fonts/texgyreadventor-italic.otf) format("truetype");
}

@font-face {
    font-family: "ReadVentorBoldItalic";
    src: local("Gothic"),
        url(./fonts/texgyreadventor-bolditalic.otf) format("truetype");
}

#root {
    min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
p,
label,
span {
    color: #69797e;
    font-family: "ReadVentorBold";
}

p,
span,
label {
    font-family: "ReadVentor";
    font-size: 14px;
}

.container-fluid {
    padding: 0px 30px;
}

.bottom-border {
    border-bottom: 1px solid #52565f;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.full-width {
    width: 100%;
}

.buttons-container button {
    margin: 0px 10px;
}

.btn-secondary {
    color: #fff;
    background-color: #69797e;
    border-color: #69797e;
}

.error-text {
    color: #ed4337 !important;
}

.slide-enter {
    transform: translateX(100%);
}
.slide-enter-active {
    transform: translateX(0%);
    transition: transform 500ms ease-in-out;
}
.slide-exit {
    transform: translateX(0%);
}
.slide-exit-active {
    transform: translateX(-100%);
    transition: transform 500ms ease-in-out;
}
