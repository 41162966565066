.projectEdit-container {
    width: 100%;
    max-width: 550px;
}

.projectEdit-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.projectDesc-edit {
    height: 200px !important;
}

.projectEdit-button {
    width: 140px;
}

.users-add-new {
    background-color: white;
    color: #69797e;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #69797e;
    padding: 5px 20px;
    text-align: center;
    cursor: pointer;
    text-align: center !important;
}
