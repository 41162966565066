.child-chapter-container {
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 794px;
    padding: 5px 20px;
    position: relative;
}

.child-chapter-container h5 {
    color: grey;
    margin-bottom: 2px;
}

.child-chapter-actions-container {
    position: absolute;
    right: 0;
    top: 0;
}

.child-chapter-description {
    font-size: 0.8rem;
}