.modal-images {
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
}
.modal-image-card {
    display: flex;
    text-align: center;
    flex-direction: column;
    flex-grow: 0;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    align-items: center;
    border: 2px solid transparent;
    padding: 15px;
    justify-content: space-between;
    max-width: 250px;
}
.modal-image-card:hover {
    border-color: #69797e;
}
.modal-image-card .image-background {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    background-color: white;
}
.modal-image-card img {
    width: 200px;
}
.modal-image-card label {
    display: block;
    margin-bottom: 0;
    justify-self: flex-start;
}
.input-group {
    display: flex;
    flex-direction: row;
}
.input-group button {
    margin-left: 20px;
}
