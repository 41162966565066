.landing-page-container h3 {
    margin-bottom: 20px;
}
.landing-page-icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;   
}
.landing-page-icons .card {
    flex: 0 0 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 25px;
}
.landing-page-icons svg {
    width: 100px;
    height: 100px;
    transition: transform 0.1s ease-in-out;
}
.landing-page-icons .card:hover svg {
    transform: translateY(-5px);
}
