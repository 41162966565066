.loading-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.centered-element {
    margin: 30px 0;
    height: 200px;
    width: 200px;
    position: relative;
    color: #69797e;
}
.loader {
    position: absolute;
    height: 200px;
    width: 200px;
    top: 0;
    left: 0;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
