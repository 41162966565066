.modal {
    position: relative;
    display: initial;
    width: initial;
    height: initial;
    overflow: hidden;
    outline: initial;
    padding: 40px 30px;
    border-radius: 15px;
    background-color: #f1f2f2;
    border: 1px solid #69797e;
    max-width: 90%;
}

.modal hr {
    background-color: #69797e;
}

.content-wrapper {
    overflow-y: auto;
    max-height: 85vh;
    padding: 0px 10px;
    display: inline-block;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(1, 1, 1, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
}
