.paragraph-container {
    margin-top: 10px;
    width: 100%;
    max-width: 794px;
    position: relative;
}

.paragraph-container p {
    margin-bottom: 5px;
}

.paragraph-content-container {
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    padding: 5px 20px;
}

.paragraph-actions-container {
    position: absolute;
    right: 0;
    top: 26px;
}

.paragraph-actions-container svg {
    width: auto;
    height: 24px;
}

.remove-all-styles * {
    font-family: unset;
    color: unset;
}