.projects-container {
    position: center;
    align-items: center;
    width: 100%;
}
.projects-container-header {
    display: flex;
    margin: 0 0 10px 10px;
}
.flex-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
}
.newProject-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}
.newProject-container .input {
    width: 600px;
}
.newProject-buttons {
    border-radius: 5px;
    border: 1px solid #69797e;
    padding: 5px 20px;
    text-align: center;
    margin-top: 15px;
}
.projectDescription-container {
    height: 200px !important;
}
.icon {
    height: auto;
    width: 18px;
    margin-right: 10px;
    color: white;
}
.addProject-button {
    display: inline-flex;
    align-items: center;
    margin: 0px !important;
    margin-right: 15px !important;
    width: 140px;
}
