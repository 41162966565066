.alert-msg-container {
    border: 1px solid #69797e;
    background-color: white;
    color: #69797e;
    border-radius: 4px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
}

.alert-msg-container p {
    margin: 0px;
}

.alert-msg-container svg {
    margin-left: 15px;
    cursor: pointer;
}
