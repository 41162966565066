.project-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.project-buttons {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 500px;
}

.project-container h3 {
    padding-bottom: 20px;
}

.component-container {
    width: 500px;
}

.dropdown-location {
    position: relative;
    display: inline-block;
}

.icon {
    height: auto;
    width: 18px;
    margin-right: 10px;
    fill: currentColor;
}

.project-button {
    display: inline-flex;
    align-items: center;
    margin: 0px !important;
    width: 140px;
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16;
}

.thumbsContainer img {
    display: inline-flex;
    border-radius: 2px;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
}

.image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
}

.project-tabs {
    width: 500px;
    margin-bottom: 20px;
    cursor: pointer;
}

.project-tabs a {
    background-color: #69797e;
    color: white;
    margin: 0 1px;
    border-radius: 0.5rem 0.5rem 0 0 !important;
    border: none !important;
}

.project-tabs svg {
    height: 20px;
    width: 20px;
}

.project-tabs .active {
    color: white !important;
    background-color: rgba(105, 121, 126, 0.8) !important;
}

.project-tabs a:hover {
    color: white !important;
    background-color: rgba(105, 121, 126, 0.6) !important;
}
