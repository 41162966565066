.layout-container {
    display: flex;
    flex-direction: row;
    min-height: 100%;
}

.layout-container aside {
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 100vh;
    height: auto;
}

.layout-container main {
    padding: 80px 80px 50px;
    z-index: 1;
}

div#body {
    width: 100%;
}

@media only screen and (max-width: 950px) {
    .layout-container main {
        padding: 80px 40px 50px;
    }
}