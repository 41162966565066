@keyframes hide-logo {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.sidebar-container {
    background-color: #849195;
    height: 100%;
    color: #69797E;
    width: 80px;
    transition: width 1s linear, background-color 1s;
    transition-delay: 1s;
    overflow-x: hidden;
}

.sidebar-container.expanded {
    width: 195px;
    max-width: 195px;
    background-color: #E6E7E8;
    transition-delay: 0s;
}

.subnav-container {
    position: absolute;
    height: 100%;
    left: 80px;
    background: #BCC4C4;
    width: 195px;
    transition: left 1s linear 1s, width 500ms linear 0s;
    overflow: hidden;
}

.sidebar-content-container {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 100;
    height: 100%;
}

.sidebar-container.expanded .subnav-container {
    left: 195px;
    transition-delay: 0s;
}

.subnav-container.closed {
    width: 0px;
}

#logo-image-container {
    width: 80%;
    height: 35px;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

#logo-image {
    width: 100%;
    height: auto;
}

.collapsed #logo-image {
    width: auto;
}

.animated {
    animation: hide-logo 2s infinite;
}

.collapsed .sidebar-item-icon {
    margin-right: 0px;
    color: white;
    transition-delay: 1s;
}

.sidebar-container hr {
    background-color: #69797E;
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    transition: max-height 1.5s, opacity 1s, margin-top 1s background-color 1s;
    transition-delay: 0s;
}

.collapsed hr {
    background-color: white;
    transition-delay: 1s;
}

.sidebar-content-container > h3 {
    margin-top: 20px;
    margin-bottom: 0px;
}

.hide_on_collapse {
    transition: max-height 1.5s, opacity 1s, margin-top 1s, margin-bottom 1s;
    white-space: nowrap;
    transition-delay: 1s;
    max-height: 40px;
    opacity: 1;
}

.collapsed .hide_on_collapse {
    max-height: 0px;
    margin-top: 0px;
    opacity: 0;
    transition-delay: 0s;
    border: none;
    overflow-y: hidden;
}

@media (min-height:850px) {
    .sidebar-container {
        width: 120px;
    }

    .subnav-container {
        left: 120px;
        width: 290px;
    }

    .sidebar-container.expanded {
        width: 290px;
        max-width: 290px;
    }

    .sidebar-container.expanded .subnav-container {
        left: 290px;
    }

    #logo-image-container {
        height: 50px;
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .sidebar-container hr {
        margin-left: 25px;
        margin-right: 25px;
        width: calc(100% - 50px);
    }
}