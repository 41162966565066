.calculation-avatar-container {
    margin-top: 10px;
    width: 100%;
    max-width: 794px;
}

.calculation-avatar-container p {
    margin-bottom: 5px;
}

.calculation-content-container {
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    padding: 5px 20px;
    position: relative;
}

.calculation-content-container td {
    padding: 2px 10px;
}

.calculation-content-container td:first-child {
    padding-left: 0px;
}

.calculation-content-results {
    margin-top: 10px;
}

.calculation-actions-container{
    position: absolute;
    top: 0;
    right: 0;
}