.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
}
.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.dashboard-card {
    background-color: white;
    text-align: center;
    justify-content: center;
    border: 0 solid transparent;
    border-radius: 20px;
    margin: 10px;
    color: #69797e;
    padding: 5px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(105, 121, 126, 0.5);
    -moz-box-shadow: 0px 0px 5px 1px rgba(105, 121, 126, 0.5);
    box-shadow: 0px 0px 5px 1px rgba(105, 121, 126, 0.5);
}
.card h1 {
    font-size: calc(10px + 2.2vw);
}
.card h4 {
    font-size: calc(10px + 1vw);
}
@media (max-width: 900px) {
    .dashboard-cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .dashboard-cards {
        grid-template-columns: repeat(1, 1fr);
    }
}
#dashboard-info-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
}
