#register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 15px;
    margin-top: 4vh;
    background-color: #69797E;
    min-width: 390px;
    padding-bottom: 35px;
}

#register-container h1, #register-container h2 {
    margin: 25px;
    font-size: 2.5em;
    color: white;
    text-align: center;
}

#register-container h2 {
    font-size: 2em;
    margin-top: 0;
}

#register-container .lang-selector {
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 4px 12px;
    background-color: #555A60;
    padding-right: 35px;
}

.register-button, #register-container input, #register-container .lang-selector {
    border: none;
    border-radius: 10px;
    margin: 0px 50px;
    width: 300px;
    font-size: 1.1rem;
}

#register-container p {
    color: white;
    margin: 0px 50px;
    margin-bottom: 15px;
    text-align: center;
}

#register-container label {
    display: block;
    margin: 0px 50px;
    font-size: 1.1rem;
    font-family: Arial;
    color: white;
}

#register-container input, #register-container option, #register-container select {
    padding: 4px 12px;
    background-color: #555A60;
    color: white;
}

#register-container input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

#register-container a {
    color: #FEBB20
}

.register-button {
    background-color: #FEBB20;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
}

#register-container .lang-selector svg {
    position: absolute;
    right: 0%;
    width: 30px;
    margin-right: 60px;
}

#register-container .dropdown-menu.show {
    background-color: #555A60;
    color: white;
    border-radius: 10px;
    color: #41444C;
    border: none;
    width: 300px;
}

#register-container .dropdown-menu {
    padding: 0;
    min-width: 0px;
}

#register-container .lang-option {
    border-radius: 10px;
    padding: 0 10px;
    margin-bottom: 0px;
    color: white;
    font-size: 1.1rem;
}

#register-container .lang-option:hover {
    background-color: #FEBB20;
    color: white;
}

#server-error {
    max-width: 300px;
    min-height: 24px;
    margin: 0px !important;
}

#register-select-main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media (min-width:950px) {
    #register-select-main {
        width: 100%;
        flex-direction: row;
        margin-top: 10vh;
    }

    #register-select-container {
        width: 100%;
    }
}

.register-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    border-radius: 15px;
    margin: 10vh 10px 0;
    background-color: #69797E;
    padding: 55px 20px;
}

.register-option svg {
    height: 100px;
    width: auto;
}

.register-option .image-container {
    background-color: #555A60;
    height: 175px;
    width: 175px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width:992px) {
    #register-select-main {
        padding: 0 8%;
    }
}

@media (min-width:1300px) {
    #register-select-main {
        padding: 0 15%;
    }
}

.register-option h2, .register-option p {
    margin: 25px;
    color: white;
    text-align: center;
    width: 330px;
}

.register-option h2 {
    font-size: 2.5em;
}

#register-select-container > p {
    width: 100%;
    text-align: center;
    margin: 35px 0;
}

#register-select-container > p > a {
    color: #FEBB20
}

.register-company-container {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    margin-bottom: 15px;
}

.register-company-container > div:first-child {
    padding-bottom: 25px;
}

@media (min-width:900px) {
    .register-company-container {
        flex-direction: row;
    }

    .register-company-container > div:first-child {
        border-right: 1px solid white
    }
}

