#paragraph-form {
    width: 90vw;
    max-width: 794px;
}

#paragraph-form input {
    width: 600px;
}

.public-DraftEditor-content * {
    font-family: unset;
    color: unset;
}
