.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
}

.thumbsContainer img {
  display: inline-flex;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
  margin-top: 30px;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
}

.img {
  display: block;
  width: auto;
  height: 100%;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dragndrop-container {
  width: 100%;
}
