.company-container {
    width: 100%;
}

.company-tabs-container {
    margin-bottom: 20px;
}

.company-tab-button {
    display: inline-flex;
    align-items: center;
    margin: 0px !important;
    margin-right: 15px !important;
    width: 140px;
}

.company-container h3 {
    margin-bottom: 1.5rem;
}

.company-container td {
    padding-right: 15px;
}

.company-tab-button svg {
    height: auto;
    width: 20px;
    margin-right: 10px;
}

.company-info-container {
    padding-right: 50px;
}

#modal-message {
    text-align: center;
    margin-bottom: 30px;
}

#error-message {
    text-align: center;
    color: red;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
}
