.actions-container {
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 500ms;
    flex-direction: row;
    display: flex;
}

.action-container {
    padding: 2px;
    cursor: pointer;
}

.action-container:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

.active.actions-container {
    opacity: 1;
    transition: opacity 500ms;
}
