.project-card {
    width: 300px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    margin: 10px;
    border-radius: 24px;
    border: 0 solid transparent;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(105, 121, 126, 0.5);
    -moz-box-shadow: 0px 0px 5px 1px rgba(105, 121, 126, 0.5);
    box-shadow: 0px 0px 5px 1px rgba(105, 121, 126, 0.5);
    transition: 0.1s;
}
.project-card:hover {
    -webkit-box-shadow: 0px 0px 5px 3px rgba(105, 121, 126, 0.5);
    -moz-box-shadow: 0px 0px 5px 3px rgba(105, 121, 126, 0.5);
    box-shadow: 0px 0px 5px 3px rgba(105, 121, 126, 0.5);
}
.project-card img {
    width: 200px;
    margin: 20px auto;
}
.project-card .card-footer img {
    width: 35px;
    margin: 5px;
    border-radius: 50%;
}
.project-card .card-header {
    border-radius: 16px 16px 0 0;
    background-color: #f1f2f2;
}
.project-card .card-footer {
    min-height: 46px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 0 0 16px 16px;
    background-color: #f1f2f2;
    padding: 0;
}
.project-card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-card hr {
    margin: 0;
}
.project-card p {
    margin: 0;
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.project-card-actions {
    position: absolute;
    top: 0;
    right: 0;
}
.project-card-actions .actions-container {
    border-radius: 0 24px 0 0;
}
.buttons-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.buttons-modal button {
    width: 60px;
}
