.user-container {
    width: 60%;
    max-width: 500px;
}
.user-container h3,
.user-container h4 {
    margin-bottom: 30px;
}
.img-container img {
    max-width: 200px;
}
.img-container {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.buttons-container {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: space-around;
}
