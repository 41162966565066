.sidebar-item-container {
    padding-top: 5px;
    width: 100%;
    transition: background-color 500ms linear;
    cursor: pointer;
    position: relative;
}

.sidebar-item-container h5, .sidebar-item-container p {
    margin-bottom: 0px;
}

.sidebar-item-container h5 {
    font-size: 0.7rem;
}

.sidebar-item-container p {
    font-size: 14px;
}


.sidebar-item {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    padding: 0 20px;
    justify-content: center;
}

.sidebar-item-icon {
    width: 24px;
    height: auto;
    color: #69797e;
    transition: color 1s;
    transition-delay: 0s;
    flex-shrink: 0;
}

.collapsed .sidebar-item-icon {
    margin-right: 0px;
    color: white;
    transition-delay: 1s;
}

.sidebar-item-text {
    transition: max-width 1.5s, opacity 1.5s, margin-left 1.5s;
    white-space: nowrap;
    transition-delay: 1s;
    max-width: 300px;
    opacity: 1;
    overflow: hidden;
    margin-bottom: 0px;
    margin-left: 10px;
    flex-grow: 1;
    height: 0.9rem;
}

.collapsed .sidebar-item-text {
    transition-delay: 0s;
    max-width: 0px;
    opacity: 0;
    margin-left: 0px;
}

.sidebar-item-container:hover {
    text-decoration: none;
    background-color: #BCC4C4;
}

.sidebar-item-container.active {
    text-decoration: none;
    background-color: #BCC4C4;
}

@media (min-height:850px) {
    .sidebar-item {
        height: 66px;
        padding: 0 25px;
    }

    .sidebar-item-icon {
        width: 34px;
    }

    .sidebar-item-container h5 {
        font-size: 1.10rem;
    }

    .sidebar-item-text {
        max-width: 300px;
        margin-left: 20px;
        height: 1.4rem;
    }
}