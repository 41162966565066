.dropzone-container {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: rgb(238, 238, 238);
    border-style: dashed;
    background-color: rgb(250, 250, 250);
    color: rgb(189, 189, 189);
    outline: currentcolor none medium;
    transition: border 0.24s ease-in-out 0s;
}

.images-preview {
    margin-top: 20px;
}

.images-preview > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.images-preview svg {
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    cursor: pointer;
    color: #69797e;
    margin-left: 5px;
}

.images-preview a {
    color: #69797e;
    text-decoration: underline;
}
