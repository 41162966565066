body {
    background-color: #f1f2f2;
    position: relative;
}

#login-form-container {
    width: 450px;
    position: absolute;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    top: 50%;
    background-color: #69797e;
    padding-bottom: 10px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#login-form-container p {
    color: white;
}

#login-form-container label {
    color: white;
    display: block;
    font-size: 1.1rem;
    font-family: Arial;
    margin-bottom: 0px;
}

#login-form-container a {
    color: #f1c40f;
}

.container-center {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login-button,
#login-form-container input {
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin: 0;
}

#login-form-container .form-group {
    margin-bottom: 1rem;
    width: 70%;
}

#login-form-container input {
    background-color: #92a1a5;
    padding: 4px 12px;
    color: white;
    width: 100%;
    height: 2.2em;
}

#login-form-container input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
}

.login-button,
.login-button:focus,
.login-button:active,
.login-button:disabled {
    background-color: #f1c40f;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 70%;
    opacity: 1;
}

.logo-container {
    margin: 40px 0px;
}

.logo-container svg {
    height: 50px;
    margin-top: 20px;
}

.login-error {
    min-height: 24px;
    width: 300px;
    margin: 0px;
    text-align: center;
    color: #f1c40f !important;
}

input:focus {
    outline: none;
}

input::placeholder {
    color: white;
}

#login-form-container hr {
    border: solid 0.5px white;
    width: 85%;
    margin-bottom: 30px;
    margin-top: 10px;
}

.right svg {
    height: 12px;
}

.right p {
    text-align: end;
    font-size: 0.6em;
    margin-bottom: 10px;
}

#title-container h5,
#title-container h6 {
    color: white;
    margin: 0;
    position: absolute;
    left: 0;
    margin-left: 30px;
    margin-top: 14px;
}

#title-container > h5 {
    font-size: 13px;
}

#title-container > h6 {
    margin-top: 28px;
    font-size: 10px;
}

.spinner-border {
    width: 22px;
    height: 22px;
}
