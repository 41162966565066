.sections-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}


.chapters-container{
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-left: 5px;
}

.chapters-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 20px;
    width: 100%;
    color: #69797E;
}

.sections-content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;
    position:relative;
}

.sections-content-container a {
    width: 100%;
}

.buttons-modal{
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
}

.sections-actions-container{
    position:absolute;
    top:0;
    right:0;
}

.sections-actions-container svg{
    height:37;
    width:auto;
}

.sections-add-new {
    background-color: white;
    color: #69797E;
    margin: 10px 0;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #69797E;
    padding: 5px 20px;
    text-align: center;
    cursor: pointer;
}

.sections-add-new a {
    color: #69797E;
}