.company-payments-container form {
    max-width: 600px;
    min-width: 300px;
}

.company-payments-container h5 {
    margin-top: 20px;
}

.company-payments-container .subscription-buttons {
    display: flex;
}

.company-payments-container .subscription-buttons > button {
    margin-right: 15px;
}

.company-payments-container .payments-table-container {
    max-width: 800px;
}

.company-payments-container .payments-table-container .more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 33px;
    color: #69797e;
    background-color: white;
    border-bottom: 1px solid #DEE2E6;
    border-top: 1px solid #DEE2E6;
    cursor: pointer;
}

.company-payments-container .payments-table {
    margin: 20px 0 0;
    color: #69797e;
    background-color: white;
}

.company-payments-container .payments-table td, .company-payments-container .payments-table th {
    padding-left: 15px;
    padding-right: 0px;
}

.company-payments-container .payments-table .action-required {
    font-weight: bold;
}

.company-payments-container .payments-table .confirm-payment {
    cursor: pointer;
    text-decoration: underline;
}

.checkbox-container {
    margin-left: 20px;
}

button[disabled] {
    cursor: not-allowed;
}