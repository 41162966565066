.subnav-item-container {
    width: 100%;
    cursor: pointer;
    display: block;
}

.closed .subnav-item-container {
    display: none;
}

.subnav-item-container p {
    margin-bottom: 0px;
    color: white;
    font-size: 0.6rem;
}

.subnav-chevron {
    color: white;
    flex-shrink: 0;
    width: 18px;
    height: auto;
}

.subnav-item {
    display: flex;
    flex-direction: row;
    height: 40px;
    align-items: center;
    padding: 0 15px;
    justify-content: center;
}

.subnav-item-icon {
    width: 18px;
    height: auto;
    color: white;
    margin-left: 0px;
    transition: color 200ms linear;
    flex-shrink: 0;
}

.subnav-item-container:hover .subnav-item-icon {
    color: #69797E;
}

.subnav-item-container.active .subnav-item-icon {
    color: #69797E;
}

.subnav-item-text {
    white-space: nowrap;
    max-width: 145px;
    opacity: 1;
    margin-bottom: 0px;
    margin-left: 10px;
    flex-grow: 1;
    overflow: hidden;
}

.subnav-item-text p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: left 2s, width 2s;
    position: relative;
    left: 0px;
}

.subnav-item-text p:hover {
    text-overflow: clip;
}

.subnav-item-text a:hover, a:visited, a:link, a:active {
    text-decoration: none;
}

.subnav-divider {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    background-color: white !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: calc(100% - 20px) !important;
}

.top-divider {
    margin-top: 25px !important;
    display: block;
}

.closed .top-divider {
    display: none;
}

@media (min-height:850px) {
    .subnav-item-icon {
        width: 24px;
        margin-left: 10px;
    }

    .subnav-item {
        height: 50px;
        padding: 0 25px;
    }

    .subnav-chevron {
        width: 24px;
    }

    .subnav-divider {
        margin-left: 25px !important;
        margin-right: 25px !important;
        width: calc(100% - 50px) !important;
    }

    .subnav-item-text {
        margin-left: 20px;
    }

    .subnav-item-container p {
        font-size: 1rem;
    }

}